@import "../styles/const";

.users {

  &_title {
    font-size: calcVH(18);
    margin-bottom: 8px;
  }

  &_wrapper {
    border: 1px solid #FFFFFF;
    border-radius: calcVH(10);
    padding: calcVH(13) calcVH(16);
  }

  &_filter {
    font-size: calcVH(22);
    line-height: 130%;
    color: $primary;
    margin-bottom: calcVH(13);

    input {
      width: 100%;
    }
  }


  &_list {
    position: absolute;
    top: 0;
    right: calcVH(-30);
    width: 30%;
  }

}

.user {
  overflow: auto;
  height: calcVH(660);
  cursor: pointer;

  &_item {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: calcVH(8) 0;
    border-bottom: calcVH(1) solid $primary;

    &:last-child {
      border-bottom: none;
    }
  }

  &_info {
    width: 65%;
  }

  &_name {
    font-size: calcVH($text-small);
    line-height: 130%;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
  }

  &_position {
    font-size: calcVH(14);
    line-height: 130%;
    color: $primary;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
  }

  &_avatar {
    width: 20%;

    &--wrapper {
      width: calcVH(46);
      height: calcVH(46);
      display: flex;
      align-items: center;
      justify-content: center;
      background-color: #C4C4C4;
      border-radius: 50%;

      svg {
        width: calcVH(33);
        height: calcVH(33);
      }
    }

    img {
      width: 100%;
      height: 100%;
      border-radius: 50%;
    }
  }

  &_check {
    width: 10%;
  }
}
