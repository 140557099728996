@import "../../styles/const";

.date-picker {
  &_wrapper {
    top: calcVH(60);
    z-index: 10;
    left: 0;
    position: absolute;
    background-color: $background;
    width: calcVH(500);
    padding: calcVH(24) calcVH(24) 0 calcVH(24);
    border: calcVH(1) solid $border;
    border-radius: calcVH(8);
    box-shadow: 0 calcVH(4) calcVH(20) 0 rgba(228, 236, 239, 0.25);
  }
  &_row {
    width: 100%;
    display: flex;
    justify-content: space-between;
  }
  &_header {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-wrap: nowrap;
    margin-bottom: calcVH(20);
  }
  &_weekDaysContainer {
    display: flex;
    justify-content: space-around;
    margin-top: calcVH(10);
  }
  &_weekDaysRangeContainer {
    display: flex;
    margin-top: calcVH(10);
    padding-left: calcVH(15);
    padding-right: calcVH(15);
  }
  &_weekDays {
    font-size: calcVH(18);
    line-height: calcVH(19);
    font-weight: 600;
    text-transform: uppercase;
  }
  &_daysContainer {
    margin-top: calcVH(15);
    margin-bottom: calcVH(20);
    flex-direction: column;
    justify-content: space-between;
  }
  &_arrow {
    padding: calcVH(8);
    border-radius: calcVH(8);
    &:hover {
      background: none;
    }
  }
  &_iconContainer {
    display: flex;
  }
  &_hidden {
    display: none !important;
  }
  &_text {
    text-align: center;
  }
}
