@import "../../styles/const";

.leftBorderRadius {
  border-radius: 50% 0 0 50%;
}
.rightBorderRadius {
  border-radius: 0 50% 50% 0;
}
.picker-day_buttonContainer {
  display: flex;
  flex-wrap: wrap;
  margin-bottom: 8px;
}
.picker-day_button {
  height: calcVH(60);
  width: calcVH(60);
  padding: 0;
  border-radius: 50%;
}
.outlined {
  border: 1px solid $primary;
}
.outlinedText {
  background: $gradient;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  color: $color-light;
}
.picker-day_buttonText {
  font-size: calcVH(24)
}
.filled {
  background: $gradient;
}
.disabled {
  color: $primary;
}
.contrast {
  color: #fff;
}
