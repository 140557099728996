@import "../styles/const";

.status {
  font-size: calcVH($title);
  font-weight: 700;
  margin: calcVH(100) 0 calcVH(32) 0;
}

.name {
  font-size: calcVH(40);
  line-height: calcVH(60);
}

.filters {
  font-size: calcVH($text);
  line-height: calcVH(36);
  color: $primary;
  margin-bottom: calcVH(32);

  &_item {
    &:after {
      content: '\00B7';
      font-size: calcVH(40);
      padding: 0 calcVH(8);
      position: relative;
      top: calcVH(7);
    }

    &:last-child {
      &:after {
        display: none;
      }
    }
  }
}

.button {
  margin-bottom: calcVH(150);

  &_checkIn {
    display: block;
    width: max-content;
    background: $gradient;
    font-size: calcVH($subtitle);
    line-height: calcVH(54);
    padding: calcVH(16) calcVH(36);
    border-radius: calcVH($border-radius);
    box-shadow: $box-shadow;
  }
}
