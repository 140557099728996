@import "../styles/const";

.notification {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  text-align: center;

  &_wrapper {
    border-radius: calcVH(63) !important;
  }

  &_title {
    font-size: calcVH($title);
    line-height: 130%;
  }

  &_text {
    font-size: calcVH($subtitle);
    line-height: 130%;
  }

  &--error {
    .gradient-border {
      border: none;
    }

    .notification_wrapper {
      border: 2px solid red;
    }
  }

  &_modal {
    padding: calcVH(40) calcVH(80);
  }

  &_icon {
    margin: calcVH(35) 0;

    svg {
      width: calcVH(100);
      height: calcVH(100);
    }

  }
}
