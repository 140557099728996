// font-size

$title: 56;
$subtitle: 36;
$text: 24;
$text-small: 18;

// color

$color-light: #fff;
$gradient: linear-gradient(10deg, #21A038 12.54%, #0CCEC2 87.2%);
$primary: #A1AEB7;
$border: #E4ECEF;
$background: #2A313A;
$error: #CC0000;
$box-shadow: 0px 10px 20px rgba(30, 140, 117, 0.19);

// size elem

$REFERENCE_HEIGHT: 982;

@function calcVH($px) {
  @return calc(($px / $REFERENCE_HEIGHT * 100) * 1vh);
}

// border

$border-radius: 24;
