@import "../styles/const";

.buttons-interval {

  &_title {
    font-size: calcVH($text);
    line-height: calcVH(36);
    text-transform: capitalize;
    margin-bottom: calcVH(16);
  }

  &_list {
    display: flex;
    align-items: center;
  }

  &_item {

    margin-right: calcVH(16);

    &:last-child {
      margin-right: 0;
    }
  }

  &_btn {
    padding: calcVH(8) calcVH(16);
    filter: drop-shadow(0px 10px 20px rgba(30, 140, 117, 0.19));
    border-radius: calcVH($border-radius);
    font-size: calcVH($text);
    line-height: calcVH(36);
  }

  &_border {
    border: 3px solid $primary;
    border-radius: calcVH($border-radius);
  }
}
