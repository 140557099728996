@import '../styles/const';

.fullscreen {
  margin-top: calcVH(30);

  &__button {
    display: flex;
    align-items: center;
    color: $primary;
  }

  &__text {
    font-weight: 600;
    font-size: calcVH(16);
    line-height: calcVH(24);
    margin-right: calcVH(24);
  }

  &__img {
    width: calcVH(23);
    height: calcVH(23);
  }
  &__version {
    font-weight: 600;
    font-size: calcVH(14);
    color: $primary;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;

    svg {
      width: calcVH(14);
      height: calcVH(14);
      margin-left:  calcVH(8);
    }
  }

  &_error {
    &_icon {
      display: flex;
      align-items: center;
      justify-content: center;
    }
    &_text {
      margin-left:  calcVH(8);
      color: $error;

    }
  }
}
