@import "../styles/const";

.slider-container {
  margin-top: calcVH(60);
  margin-bottom: calcVH(32);
  position: relative;

  .range-slider {
    height: calcVH(8);
    background: #fff;

    &__range {
      background: $gradient !important;
    }

    &__thumb {
      width: calcVH(2) !important;
      height: calcVH(28) !important;
      background: $primary !important;
      top: auto !important;
      bottom: 0 !important;
      border-radius: 0 !important;
      transform: none !important;
    }
  }
}

.slider {

  &_track {
    width: 100%;
    height: calcVH(8);
    border-radius: calcVH(10);
    background-color: $color-light;
    z-index: 1;
  }

  &_values {
    display: flex;
    align-items: center;
    justify-content: space-between;
    font-size: calcVH(14);
    line-height: 130%;
    color: $primary;
    margin-top: calcVH(20);
  }

}
