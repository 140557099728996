@import "../styles/const";

.container {
  width: 100%;
  height: 100vh;
  padding: calcVH(21);
  color: $color-light;
  overflow: hidden;

  &--available {
    background: $gradient;
  }

  &--booked {
    background: $primary;
  }
}

.wrapper {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 100%;
  height: 100%;
  background: $background;
  border-radius: calcVH($border-radius);
  padding: calcVH(35) calcVH(40);
}

.content {
  display: flex;
  position: relative;
  align-items: flex-start;
  position: relative;

  &_item {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 100%;

    &:first-child {
      width: 70%;
      padding-right: calcVH(44);
    }

    &:last-child {
      width: 30%;
    }

    &--border {
      border-right: calcVH(1) solid $primary;
    }
  }
}

.gradient-border {
  border: double 3px transparent;
  border-radius: calcVH($border-radius);
  background-image: linear-gradient(#2A313A, #2A313A),
  linear-gradient(10deg, #21A038 12.54%, #0CCEC2 87.2%);
  background-origin: border-box;
  background-clip: content-box, border-box;
  box-shadow: inset -5px -5px 250px rgba(255, 255, 255, 0.01);
  filter: drop-shadow(0px 10px 20px rgba(51, 59, 69, 0.15));
  backdrop-filter: blur(21px);
}