@import "../styles/const";

.hiddenAllBookingFormElem {
  .active-block-participants {
    min-width: 100%;
    max-width: 100%;
    top: calcVH(-430);
    left: -50%;
    transition: top 0.5s,  min-width 0.5s, left 0.5s;
  }
  .active-block-owner {
    min-width: 100%;
    max-width: 100%;
    top: calcVH(-430);
    left: 0;
    transition: top 0.5s,  min-width 0.5s;
  }
  .active-block-meeting-name {
    top: calcVH(-530);
    left: 0;
    transition: top 0.5s;
  }
  .visible {
    opacity: 0;
    visibility: hidden;
    transition: opacity 0.3ms, visibility 0s  0.3ms;
  }
}

.visible {
  opacity: 1;
  transition: opacity 1s, visibility 0s;
}

.container {
  background: $background;
}

.wrapper {
  display: block;
}

.button-back {
  display: flex;
  align-items: center;
  font-weight: 500;
  font-size: calcVH(36);
  line-height: 130%;
  margin-bottom: calcVH(24);

  &_arrow {
    width: calcVH(20);
    height: calcVH(20);
    border: solid $color-light;
    border-width: 0 calcVH(4) calcVH(4) 0;
    padding: calcVH(3);
    margin-bottom: calcVH(2);
    transform: rotate(135deg);
    margin-right: calcVH(30);
  }
}

.inputs {
  display: flex;
  align-items: center;
  justify-content: space-between;

  &_item {
    margin-right: calcVH(24);
    width: 50%;

    &:last-child {
      margin-right: 0;
    }
  }

  &_owner {
    position: relative;
    margin-right: calcVH(24);
    min-width: 48.5%;
    width: auto;
    transition: top 0.5s, min-width 0.5s, left 0.5s;
    top: 0;
    left: 0;
  }

  &_participants {
    position: relative;
    min-width: 48.5%;
    width: auto;
    transition: top 0.5s, min-width 0.5s, left 0.5s;
    top: 0;
    left: 0;
  }

  &_error {
    .input_wrapper {
      border: calcVH(1) solid red !important;
    }
  }

  .error {
    position: absolute;
    color: red;
  }
}

.label {
  display: block;
  font-weight: 500;
  font-size: calcVH(18);
  line-height: 130%;
  margin-bottom: calcVH(9);
  margin-left: calcVH(8);
}

.form {
  &_section {
    margin-bottom: calcVH(24);
  }

  &_buttons {

    &--range {
      display: flex;
      justify-content: center;
    }
  }
  &_submit {
    display: flex;
    align-items: center;
    justify-content: space-between;

    & button {
      display: block;
      width: max-content;
      background: $gradient;
      font-size: calcVH($text);
      line-height: 130%;
      padding: calcVH(16) calcVH(24);
      border-radius: calcVH($border-radius);
      box-shadow: $box-shadow;
    }
  }
  &_error {
    color: $error;
  }
}

.input {
  margin-top: calcVH(24);

  &_up  {
    position: relative;
    width: 100%;
    transition: top 0.5s;
    top: 0;
    left: 0;
  }

  &_wrapper {
    border: calcVH(1) solid $border;
    border-radius: calcVH(10);
    position: relative;
    font-size: calcVH(22);
    line-height: 130%;

    select {
      appearance: none;
      width: 100%;

      &:focus-visible {
        border-radius: calcVH(10);
      }
    }
  }

  &_date {
    height: calcVH(60);
    padding: calcVH(16);
    width: 100%;
    position: absolute;
    top: 0;
    left: 0;
    opacity: 0;
  }

  &_date-picker {
    border: calcVH(1) solid $border;
    border-radius: calcVH(10);
    position: relative;
    font-size: calcVH(19);
    line-height: calcVH(40);
    padding: calcVH(8);
    height: calcVH(60);
    @media screen and (orientation:landscape) {
      padding: calcVH(16);
      font-size: calcVH(22);
      line-height: 130%;
    }
  }

  &_elem {
    height: calcVH(60);
    padding: calcVH(16) calcVH(40) calcVH(16) calcVH(16);
    width: 100%;

    &--users {
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }
  }

  &_icon {
    position: absolute;
    top: calcVH(12);
    right: calcVH(12);
    pointer-events: none;
    background: $background;

    svg {
      width: calcVH(33);
      height: calcVH(33);
    }
  }
}

.item {
  &:first-child {
    width: 80%;
  }

  &:last-child {
    width: 20%;
    text-align: end;
  }
}

.users {
  &_buttons {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    margin-top: calcVH(24);

    &-cancel {
      padding: calcVH(16) calcVH(16);
      filter: drop-shadow(0px 10px 20px rgba(30, 140, 117, 0.19));
      border-radius: calcVH($border-radius);
      font-size: calcVH($text-small);
      line-height: 130%;
    }
    &-done  {
      display: block;
      width: max-content;
      background: $gradient;
      font-size: calcVH($text);
      line-height: 130%;
      padding: calcVH(16) calcVH(24);
      border-radius: calcVH($border-radius);
      box-shadow: $box-shadow;
      margin-left: calcVH(8);
    }
  }

  &_placeholder {
    opacity: 0.3;
  }
}

.input_select {
  &_time_list {
    height: 700%;
    width: 100%;
    border: calcVH(1) solid $border;
    border-radius: calcVH(10);
    position: absolute;
    font-size: calcVH(24);
    line-height: 130%;
    padding: calcVH(16) calcVH(40) calcVH(16) calcVH(16);
    background-color: $background;
    overflow: auto;
    z-index: 999;
  }
  &_time_item {
    padding-bottom: calcVH(20);
  }
}