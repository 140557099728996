@import "../styles/const";

.hidden {
  display: none;
}

.events-slider {
  display: flex;
  align-items: center;
  color: $primary;
  margin-bottom: calcVH(8);
  padding-left: calcVH(44);

  &_title {
    font-weight: 500;
    font-size: calcVH(18);
    line-height: 130%;
    margin-bottom: calcVH(8);
    padding-left: calcVH(44);
  }

  &_date {
    text-align: center;
    min-width: calcVH(160);
    font-weight: 600;
    font-size: calcVH(14);
    line-height: calcVH(21);
    text-transform: uppercase;
  }

  &_arrow {
    display: flex;
    align-items: center;
    justify-content: center;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    margin-bottom: calcVH(1);;
    width: calcVH(30);
    height: calcVH(20);

    svg {
      width: calcVH(8);
      height: calcVH(12);
    }
  }
}

.events {
  &_list {
    height: calcVH(700);
    overflow: auto;
    padding-left: calcVH(44);
  }

  &_item {
    margin-bottom: calcVH(8);
    position: relative;

    &:last-child {
      margin-bottom: 0;
    }
  }

  &_spinner {
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
  }
}

.event {
  border: calcVH(4) solid $primary;
  border-radius: calcVH($border-radius);

  &_private {
    font-size: calcVH(24);
  }

  &_link {
    color: $color-light;
  }

  &_wrapper {
    padding: calcVH(16);
    overflow: hidden;
  }

  &_time {
    font-weight: 700;
    font-size: calcVH($text-small);
    line-height: 130%;
    margin-bottom: calcVH(8);
  }

  &_text {
    font-size: calcVH($text);
    line-height: 130%;
    text-transform: capitalize;
  }

  &_line {
    height: calcVH(0.5);
    background-color: $primary;
    margin: calcVH(8) 0;
  }

  &_name {
    font-size: calcVH($text-small);
    line-height: 130%;
    color: $primary;
  }

  &_dote {
    width: calcVH(14);
    height: calcVH(14);
    position: absolute;
    border-radius: 50%;
    top: 40%;
    left: calcVH(-30);
    background: $primary;

    &--gradient {
      background: $gradient;
    }

    &--grey {
      background: $primary;
    }
  }
}
