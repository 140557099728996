@import "../styles/const";

.row {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: calcVH(40);
}

.logo {

  img {
    max-height: calcVH(55);
    max-width: calcVH(200);
    object-fit: contain;
    width: 100%;
  }
}

.date {
  font-weight: 600;
  font-size: calcVH($text);
  line-height: 130%;
  margin-left: calcVH(50);
}
