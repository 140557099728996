@import "../styles/const";

.wrapper-slider {
  position: relative;
  cursor: pointer;
}

.slider {
  position: relative;
}

.slider__control {
  position: relative;
}

.slider__track,
.slider__range,
.slider__left-value,
.slider__right-value {
  position: absolute;
}

.slider__track,
.slider__range {
  border-radius: calcVH(3);
  height: calcVH(8);
}

.slider__track {
  width: 100%;
  z-index: 2;
  height: calcVH(8);
  bottom: calcVH(-8);

  &--bg {
    background: $gradient;
  }
}

.slider__range {
  z-index: 2;
}

.slider__left-value,
.slider__right-value {
  width: calcVH(25);
  text-align: center;
  color: $color-light;
  font-size: calcVH(14);
  bottom: calcVH(calc(28 - 8));
}

.slider__left-value {
  left: 0;
}

.slider__right-value {
  right: 0;
}

/* Removing the default appearance */
.thumb,
.thumb::-webkit-slider-thumb {
  -webkit-appearance: none;
  -webkit-tap-highlight-color: transparent;
}

.thumb {
  pointer-events: none;
  position: absolute;
  bottom: calcVH(-8);
  outline: none;
}

.thumb--left {
  z-index: 3;
}

.thumb--right {
  z-index: 4;
}

/* For Chrome browsers */
.thumb::-webkit-slider-thumb {
  background-color: $primary;
  border: none;
  cursor: pointer;
  height: calcVH(28);
  width: calcVH(2);
  pointer-events: all;
  position: relative;
}

/* For Firefox browsers */
.thumb::-moz-range-thumb {
  background-color:  $primary;
  border: none;
  cursor: pointer;
  height: calcVH(28);
  width: calcVH(2);
  pointer-events: all;
  position: relative;
}

